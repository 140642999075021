<template>
  <svg
    id="ROARLogo"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 1020 338"
    style="enable-background: new 0 0 1020 338"
    xml:space="preserve"
    class="roar-logo"
  >
    <g id="ROARLogo-Icon">
      <path
        class="st0"
        d="M253.8,217.3c-0.4-4.2-0.8-8.1-1.1-12c-0.5-6.5-0.8-13-1.3-19.5c-0.1-2.3-0.3-4.7-0.5-7
			c-0.4-4.5-0.9-9-1.2-13.5c-0.4-4.7-0.5-9.5-0.9-14.2c-0.5-6.5-1.2-13-1.7-19.4c-0.5-6.5-0.9-13-1.3-19.5c-0.1-2-0.3-4.1-0.4-6.1
			c-0.5-7-1.1-13.9-1.6-20.9c-0.2-2.3-0.3-4.7-0.5-7c-0.4-5.1-0.8-10.2-1.2-15.4c-0.2-2.7-0.3-5.4-0.6-8c-0.4-4.4-0.8-8.9-1.2-13.3
			c-0.4-4.2-0.5-8.5-1.3-12.6c-2.3-11.1-8.4-15.9-19.4-15.9c-49.1,0-98.2-0.1-147.3-0.2c-13.5,0-27.1-0.1-40.6-0.1
			c-5,0-9.3,1.4-12,5.7c-3.4,5.4-3.9,11.6-4.1,17.7c-0.1,2.1,0.6,4.2,0.8,6.3c0.6,7.7,1.2,15.3,1.7,23c0.4,5.5,0.8,11.1,1.3,16.6
			c0.4,5.7,0.9,11.3,1.3,17c0.4,5.7,0.8,11.3,1.3,17c0.4,5.5,0.8,11.1,1.3,16.6c0.4,5.5,0.9,10.9,1.2,16.4c0.5,6.7,0.9,13.4,1.3,20.1
			c0.1,2.1,0.2,4.2,0.4,6.4c0.5,6.3,1.2,12.5,1.7,18.8c0.5,5.4,0.9,10.8,1.2,16.2c0.5,6.9,0.8,13.8,1.3,20.8c0.3,4.3,2,7.1,6.3,8.1
			c2.2,0.5,4.5,0.8,6.8,0.8c125.7,0,827.7,0,953.4,0c0.8,0,1.6,0,2.3,0c4-0.1,6.3-2.3,6.3-6.2c0-3.1,0-6.1,0-9.2
			c0-5.6-1.9-7.5-7.5-7.5c-55.2,0-686.6,0-741.8,0C255.4,217.3,254.6,217.3,253.8,217.3z M276.2,26.3c-0.8,0-1.3,0-1.7,0
			c-6.3-0.5-10.8,3.7-10.8,10.5c0,9.3-0.3,18.7-0.4,28c-0.1,10-0.3,19.9-0.4,29.9c-0.1,10-0.3,20.1-0.4,30.1
			c-0.1,9.9-0.3,19.8-0.4,29.7c-0.1,9.5-0.3,19.1-0.4,28.6c-0.1,7.2,0,14.4,0,21.6c0,3,2.2,5.2,5.4,5.6c3.8,0.4,6.4-1,6.8-4.3
			c0.5-3.6,0.6-7.2,0.9-10.8c0.4-5.3,0.9-10.5,1.3-15.8c0.2-2.3,0.2-4.5,0.4-6.8c0.5-6.1,1.2-12.3,1.7-18.4c0.4-4.6,0.5-9.3,0.9-14
			c0.5-6.4,1.1-12.8,1.7-19.2c0.5-5.4,0.9-10.8,1.3-16.2c0.5-6.6,0.8-13.2,1.3-19.7c0.1-2.1,0.3-4.2,0.4-6.4c0.4-4.5,0.9-9,1.3-13.5
			c0.3-4.4,0.5-8.9,0.8-13.4c0.4-6.1,0.8-12.2,1.3-18.3c0.2-2.6-1-4.3-3.1-5.7C281.4,26.2,278.6,26.2,276.2,26.3z M355.6,63.2
			c-0.6-1.4-0.9-3.1-1.9-4.1c-1.5-1.5-3.4-2.8-5.4-3.7c-5.9-2.4-11.1-1.8-13.2,4.9c-1.9,6-4.4,11.9-6.7,17.8
			c-3,7.9-6.1,15.8-9.1,23.7c-3,8-5.9,16.1-8.9,24.1c-2.6,7-5.4,13.9-8,20.8c-3,8.1-6,16.2-9,24.2c-2.4,6.5-4.8,12.9-7.3,19.4
			c-1.5,4.1-3,8.2-4.7,12.2c-1.5,3.5-0.5,6.3,3.2,8.1c2.6,1.3,5.6,0,7.1-3.2c1.6-3.4,3.1-6.9,4.7-10.4c4.8-10.7,9.6-21.4,14.4-32.1
			c5.1-11.3,10.1-22.5,15.2-33.8c4.8-10.8,9.6-21.5,14.4-32.3c4.6-10.3,9.2-20.5,13.8-30.8C354.7,66.7,355,65,355.6,63.2z
			 M395.3,114.2c0.5-2.4-1-4.1-2.5-5.6c-3.5-3.6-8-4.9-11.6,0.1c-1.6,2.3-3.5,4.3-5.3,6.4c-9.7,11.6-19.5,23.3-29.2,34.9
			c-11,13.2-22,26.4-33.1,39.6c-4.4,5.2-8.7,10.4-13.1,15.7c-1.3,1.6-1.4,3.4-0.5,5.2c1.7,3.6,5.4,4.1,8.4,0.8
			c8.2-8.7,16.2-17.6,24.3-26.3c11-12,22-23.9,33-35.9c9.2-10,18.4-20,27.6-30C394.7,117.6,395.8,116.2,395.3,114.2z M322.9,214.8
			c0.1,0,0.8-0.1,1.5-0.3c17-5,33.9-10,50.8-15.1c11.6-3.5,23.3-6.9,34.8-10.5c1.1-0.3,2.3-1.6,2.7-2.7c1.2-3.8-0.6-7.2-2.6-10.3
			c-1.4-2.1-3.1-2.5-5.5-1.6c-12.5,4.8-25.1,9.5-37.6,14.3c-12.9,4.9-25.8,9.9-38.7,14.9c-2.5,1-5,2-7.5,3.1c-2.1,0.9-3.3,3-2.9,4.8
			C318.4,213.5,320.1,214.8,322.9,214.8z"
      />
      <path
        class="st1"
        d="M253.8,217.7c0.8,0,1.6,0,2.5,0c55.2,0,686.6,0,741.8,0c5.6,0,7.5,2,7.5,7.6c0,3.1,0,6.2,0,9.3
			c-0.1,4-2.4,6.2-6.3,6.3c-0.8,0-1.6,0-2.3,0c-125.7,0-827.7,0-953.4,0c-2.3,0-4.6-0.3-6.8-0.8c-4.3-1-5.9-3.8-6.3-8.3
			c-0.6-7-0.9-14.1-1.3-21.1c-0.4-5.5-0.8-11-1.2-16.5c-0.5-6.4-1.1-12.7-1.7-19.1c-0.2-2.2-0.3-4.3-0.4-6.5
			c-0.4-6.8-0.8-13.6-1.3-20.5c-0.4-5.6-0.8-11.1-1.2-16.7c-0.4-5.6-0.8-11.3-1.3-16.9c-0.4-5.8-0.9-11.5-1.3-17.3
			c-0.4-5.8-0.8-11.5-1.3-17.3c-0.4-5.6-0.8-11.2-1.3-16.9c-0.6-7.8-1.1-15.6-1.7-23.4c-0.2-2.1-0.8-4.3-0.8-6.4
			c0.2-6.3,0.7-12.5,4.1-18c2.7-4.4,7.1-5.8,12-5.8c13.5,0.1,27.1,0.1,40.6,0.1c49.1,0.1,98.2,0.2,147.3,0.2
			c10.9,0,17.1,4.8,19.4,16.1c0.9,4.2,0.9,8.6,1.3,12.8c0.4,4.5,0.8,9,1.2,13.5c0.2,2.7,0.3,5.4,0.6,8.2c0.4,5.2,0.8,10.4,1.2,15.6
			c0.2,2.4,0.3,4.7,0.5,7.1c0.5,7.1,1.1,14.2,1.6,21.2c0.2,2.1,0.3,4.2,0.4,6.2c0.4,6.6,0.8,13.2,1.3,19.9c0.5,6.6,1.2,13.2,1.7,19.8
			c0.4,4.8,0.5,9.6,0.9,14.4c0.3,4.6,0.8,9.1,1.2,13.7c0.2,2.4,0.3,4.7,0.5,7.1c0.4,6.6,0.8,13.2,1.3,19.8
			C253,209.5,253.4,213.4,253.8,217.7z"
      />
      <path
        class="st1"
        d="M276.2,26.3c2.4-0.1,5.2,0,7.8,1.6c2.1,1.4,3.3,3.1,3.1,5.7c-0.5,6.1-0.9,12.2-1.3,18.3
			c-0.3,4.5-0.5,8.9-0.8,13.4c-0.4,4.5-0.9,9-1.3,13.5c-0.2,2.1-0.3,4.2-0.4,6.4c-0.4,6.6-0.8,13.2-1.3,19.7
			c-0.4,5.4-0.8,10.8-1.3,16.2c-0.5,6.4-1.2,12.8-1.7,19.2c-0.4,4.6-0.5,9.3-0.9,14c-0.5,6.1-1.1,12.3-1.7,18.4
			c-0.2,2.3-0.3,4.5-0.4,6.8c-0.4,5.3-0.8,10.5-1.3,15.8c-0.3,3.6-0.4,7.3-0.9,10.8c-0.5,3.2-3.1,4.6-6.8,4.3
			c-3.1-0.3-5.4-2.6-5.4-5.6c0-7.2-0.1-14.4,0-21.6c0.1-9.5,0.3-19.1,0.4-28.6c0.1-9.9,0.3-19.8,0.4-29.7c0.1-10,0.3-20.1,0.4-30.1
			c0.1-10,0.3-19.9,0.4-29.9c0.1-9.3,0.4-18.7,0.4-28c0-6.8,4.5-11,10.8-10.5C274.9,26.3,275.3,26.3,276.2,26.3z"
      />
      <path
        class="st1"
        d="M355.6,63.2c-0.6,1.9-0.9,3.5-1.6,5c-4.6,10.3-9.2,20.5-13.8,30.8c-4.8,10.8-9.6,21.5-14.4,32.3
			c-5,11.3-10.1,22.5-15.2,33.8c-4.8,10.7-9.6,21.4-14.4,32.1c-1.6,3.5-3,7-4.7,10.4c-1.5,3.2-4.5,4.4-7.1,3.2
			c-3.6-1.8-4.6-4.6-3.2-8.1c1.7-4,3.2-8.1,4.7-12.2c2.4-6.5,4.9-12.9,7.3-19.4c3-8.1,5.9-16.2,9-24.2c2.6-7,5.4-13.9,8-20.8
			c3-8,5.9-16.1,8.9-24.1c3-7.9,6.1-15.8,9.1-23.7c2.3-5.9,4.8-11.8,6.7-17.8c2.1-6.7,7.3-7.3,13.2-4.9c2,0.8,3.9,2.2,5.4,3.7
			C354.7,60,355,61.8,355.6,63.2z"
      />
      <path
        class="st1"
        d="M395.3,114.2c0.4,2-0.6,3.4-1.8,4.8c-9.2,10-18.4,20-27.6,30c-11,12-22,23.9-33,35.9
			c-8.1,8.8-16.1,17.6-24.3,26.3c-3.1,3.3-6.7,2.9-8.4-0.8c-0.8-1.8-0.8-3.6,0.5-5.2c4.3-5.2,8.7-10.4,13.1-15.7
			c11-13.2,22-26.4,33.1-39.6c9.7-11.6,19.5-23.3,29.2-34.9c1.8-2.1,3.7-4.1,5.3-6.4c3.6-5.1,8-3.7,11.6-0.1
			C394.3,110.1,395.8,111.8,395.3,114.2z"
      />
      <path
        class="st1"
        d="M322.9,214.8c-2.8,0-4.5-1.3-4.9-3.3c-0.4-1.8,0.8-3.9,2.9-4.8c2.5-1.1,5-2.1,7.5-3.1
			c12.9-5,25.8-9.9,38.7-14.9c12.5-4.8,25-9.6,37.6-14.3c2.4-0.9,4.1-0.5,5.5,1.6c2,3.1,3.8,6.4,2.6,10.3c-0.4,1.1-1.6,2.3-2.7,2.7
			c-11.6,3.6-23.2,7-34.8,10.5c-16.9,5-33.9,10.1-50.8,15.1C323.7,214.7,323,214.8,322.9,214.8z"
      />
    </g>
    <g id="ROARLogo-Text">
      <path
        class="st1"
        d="M430.3,218.3V45.9h40.6c16.4,0,29.4,4.3,39,13c9.6,8.6,14.4,20.3,14.4,35.1c0,10-2.4,18.7-7.1,26
			c-4.7,7.3-11.5,12.8-20.3,16.4c5.2,3.6,10.2,8.6,15.2,14.8c4.9,6.3,11.9,17.2,20.9,32.7c5.6,9.8,10.2,17.2,13.6,22.1l8.6,12.3
			h-27.6l-7.1-11.4c-0.2-0.4-0.7-1.1-1.4-2.1l-4.5-6.8l-7.2-12.6l-7.8-13.5c-4.8-7.1-9.2-12.7-13.2-16.9c-4-4.2-7.6-7.2-10.8-9.1
			c-3.2-1.8-8.6-2.8-16.2-2.8h-6v75H430.3z M460.5,66.9h-7v54.4h8.8c11.8,0,19.8-1.1,24.2-3.2c4.4-2.1,7.8-5.4,10.2-9.7
			c2.4-4.3,3.7-9.2,3.7-14.7c0-5.4-1.4-10.3-4.1-14.7c-2.7-4.4-6.5-7.5-11.4-9.3C480,67.8,471.8,66.9,460.5,66.9z"
      />
      <path
        class="st1"
        d="M776,44.9h16.3l72.7,173.4h-23.7l-21.6-51.1h-69.1l-20.3,51.1h-23.8L776,44.9z M810,145.3l-26.1-62.4
			l-24.2,62.4H810z"
      />
      <path
        class="st1"
        d="M873,218.3V45.9h40.6c16.4,0,29.4,4.3,39,13c9.6,8.6,14.4,20.3,14.4,35.1c0,10-2.4,18.7-7.1,26
			c-4.7,7.3-11.5,12.8-20.3,16.4c5.2,3.6,10.2,8.6,15.2,14.8c4.9,6.3,11.9,17.2,20.9,32.7c5.6,9.8,10.2,17.2,13.6,22.1l8.6,12.3
			h-27.6l-7.1-11.4c-0.2-0.4-0.7-1.1-1.4-2.1l-4.5-6.8l-7.2-12.6l-7.8-13.5c-4.8-7.1-9.2-12.7-13.2-16.9c-4-4.2-7.6-7.2-10.8-9.1
			c-3.2-1.8-8.6-2.8-16.2-2.8h-6v75H873z M903.2,66.9h-7v54.4h8.8c11.8,0,19.8-1.1,24.2-3.2c4.4-2.1,7.8-5.4,10.2-9.7
			c2.4-4.3,3.7-9.2,3.7-14.7c0-5.4-1.4-10.3-4.1-14.7c-2.7-4.4-6.5-7.5-11.4-9.3C922.7,67.8,914.5,66.9,903.2,66.9z"
      />
      <path
        class="st1"
        d="M715.1,122.7c0,6.1,0.5,12.2-0.1,18.2c-1.1,12.2-4.3,23.8-10.5,34.5c-8.3,14.3-19.6,25.4-34.2,33.2
			c-9.4,5-19.3,8.1-29.9,9.3c-4.5,0.5-9.1,1.3-13.7,1.3c-20.8-0.4-39.8-6.4-55.5-20.3c-14.1-12.5-23.9-27.9-27.4-46.7
			c-1.4-7.5-2.7-15.2-2.5-22.8c0.4-21.2,6.2-40.7,19.9-57.3c12.6-15.3,28.5-25.7,48.2-29c8.9-1.5,18.2-2,27.3-1.3
			c23.8,1.8,43.6,11.9,59,30.5c8.9,10.8,14.8,22.9,17.5,36.6c0.9,4.6,1.7,9.2,2.5,13.8C715.4,122.7,715.2,122.7,715.1,122.7z
			 M630.9,156c-0.8,2.4,0.3,3.9,2.5,4.1c3.8,0.3,7.6,0.2,11.3,0.1c3.1-0.1,5.8-1.4,7.2-4.1c2.2-4.1,4.4-8.2,5.8-12.5
			c3.9-12.6,4.6-25.4,2.2-38.5c-1.3-7.1-7-13.3-13.9-15c-10.4-2.6-20.9-2.7-31.5-1.3c-16.3,2.3-20.2,10.6-21.4,22.7
			c-1.4,13.5,0.7,26.7,6.4,39.1c1.8,3.9,3.5,8.1,8.5,9.5c5.3,1.4,10.3,1,15.3-0.9c0-1.2,0-2.3,0-3c-1.9-0.6-3.6-1.1-5.3-1.8
			c-1.9-0.8-4.9-1.3-4-4.1c0.9-3.1,3.9-1.8,6.1-1.5c0.3,0,0.6,0.3,0.9,0.3c3.2,0.2,6.5,0.7,9.7,0.6c2.2,0,4.3-1,6.5-1.2
			c1-0.1,2,1,3,1.5c-0.7,1.2-1,2.9-2,3.4C636,154.7,633.4,155.2,630.9,156z M611.9,167.5c8.9,12.2,22.4,12.2,31.1-0.2
			c-4.1-0.6-8.3-1.3-12.6-1.9c-1.3-0.2-2.7-0.9-3.9-0.7C621.5,165.5,616.6,166.5,611.9,167.5z"
      />
    </g>
    <g id="ROARLogo-Text_Full">
      <path d="M49.4,277.4v36.9h-4.6v-36.9h-10v-4.3h24.6v4.3H49.4z" />
      <path
        d="M60,269.3h4.4v22.4c0.8-1,3.1-3.6,7.3-3.6c3,0,5.8,1.2,7.3,3.5c1.3,1.9,1.5,4,1.5,6.9v15.9h-4.4v-15.7
			c0-1.5-0.1-3.3-1.2-4.7c-0.9-1.1-2.2-1.9-4.2-1.9c-1.5,0-3.2,0.4-4.5,2c-1.7,2-1.9,5.1-1.9,6.9v13.4H60V269.3z"
      />
      <path
        d="M111.1,307.7c-0.7,1.4-1.9,3-3.1,4.1c-2.3,2.1-5.2,3.1-8.5,3.1c-6.2,0-12.7-3.9-12.7-13.4c0-7.7,4.7-13.6,12.4-13.6
			c5,0,8.2,2.5,9.7,4.7c1.6,2.3,2.5,6.2,2.4,9.6H91.4c0.1,5.3,3.7,8.7,8.2,8.7c2.1,0,3.8-0.6,5.2-1.9c1.2-1,2.1-2.3,2.7-3.5
			L111.1,307.7z M106.7,298.7c-0.7-4-3.8-6.7-7.5-6.7c-3.8,0-6.9,2.8-7.5,6.7H106.7z"
      />
      <path
        d="M140.2,273.1c2.8,0,6.9,0.2,10,2.3c2.4,1.6,4.5,4.8,4.5,9.1c0,6.3-4.1,10.9-10.4,11.4l13.1,18.4h-5.6l-12.5-18h-1.2v18
			h-4.6v-41.2H140.2z M138.2,292.2h2.9c5.7,0,9.1-2.7,9.1-7.5c0-2.3-0.7-4.6-3.3-6.1c-1.9-1-4-1.2-5.9-1.2h-2.8V292.2z"
      />
      <path
        d="M182.5,288.7h4.4v25.6h-4.4v-3.6c-1.5,1.9-4.3,4.3-8.8,4.3c-6.5,0-12.5-4.6-12.5-13.6c0-8.8,5.9-13.4,12.5-13.4
			c4.9,0,7.6,2.7,8.8,4.3V288.7z M165.8,301.4c0,5.5,3.1,9.6,8.4,9.6c5.4,0,8.5-4.5,8.5-9.6c0-6.6-4.6-9.5-8.5-9.5
			C169.8,292,165.8,295.2,165.8,301.4z"
      />
      <path
        d="M199.8,328.5h-4.4v-39.8h4.4v3.6c3-3.8,6.9-4.2,8.8-4.2c8,0,12.5,6.7,12.5,13.6c0,7-4.5,13.4-12.3,13.4c-4.1,0-7-1.9-9-4.3
			V328.5z M216.5,301.6c0-6.3-4.3-9.6-8.4-9.6c-4.1,0-8.5,3.4-8.5,9.6c0,5.2,3.3,9.5,8.6,9.5C213.3,311.1,216.5,306.8,216.5,301.6z"
      />
      <path
        d="M230.1,275.8c1.7,0,3.1,1.4,3.1,3.1c0,1.7-1.4,3.1-3.1,3.1s-3.1-1.4-3.1-3.1C226.9,277.2,228.3,275.8,230.1,275.8z
			 M232.3,288.7v25.6h-4.4v-25.6H232.3z"
      />
      <path
        d="M260.3,269.3h4.4v45h-4.4v-3.6c-1.5,1.9-4.3,4.3-8.8,4.3c-6.5,0-12.5-4.6-12.5-13.6c0-8.8,5.9-13.4,12.5-13.4
			c4.9,0,7.6,2.7,8.8,4.3V269.3z M243.6,301.4c0,5.5,3.1,9.6,8.4,9.6c5.4,0,8.5-4.5,8.5-9.6c0-6.6-4.6-9.5-8.5-9.5
			C247.6,292,243.6,295.2,243.6,301.4z"
      />
      <path
        d="M286.4,293.7c0-12.3,9.6-21.3,21.4-21.3s21.4,9,21.4,21.3c0,12.2-9.5,21.3-21.4,21.3C295.9,315,286.4,306,286.4,293.7z
			 M291.1,293.7c0,9.4,7.5,17,16.7,17s16.7-7.5,16.7-17s-7.5-17-16.7-17S291.1,284.3,291.1,293.7z"
      />
      <path
        d="M336.6,288.7h4.4v3c0.8-1,3.1-3.6,7.3-3.6c3,0,5.8,1.2,7.3,3.5c1.3,1.9,1.5,4,1.5,6.9v15.9h-4.4v-15.7
			c0-1.5-0.1-3.3-1.2-4.7c-0.9-1.1-2.2-1.9-4.2-1.9c-1.5,0-3.2,0.4-4.5,2c-1.7,2-1.9,5.1-1.9,6.9v13.4h-4.4V288.7z"
      />
      <path d="M370,269.3v45h-4.4v-45H370z" />
      <path
        d="M380.7,275.8c1.7,0,3.1,1.4,3.1,3.1c0,1.7-1.4,3.1-3.1,3.1s-3.1-1.4-3.1-3.1C377.5,277.2,378.9,275.8,380.7,275.8z
			 M382.9,288.7v25.6h-4.4v-25.6H382.9z"
      />
      <path
        d="M391.3,288.7h4.4v3c0.8-1,3.1-3.6,7.3-3.6c3,0,5.8,1.2,7.3,3.5c1.3,1.9,1.5,4,1.5,6.9v15.9h-4.4v-15.7
			c0-1.5-0.1-3.3-1.2-4.7c-0.9-1.1-2.2-1.9-4.2-1.9c-1.5,0-3.2,0.4-4.5,2c-1.7,2-1.9,5.1-1.9,6.9v13.4h-4.4V288.7z"
      />
      <path
        d="M443,307.7c-0.7,1.4-1.9,3-3.1,4.1c-2.3,2.1-5.2,3.1-8.5,3.1c-6.2,0-12.7-3.9-12.7-13.4c0-7.7,4.7-13.6,12.4-13.6
			c5,0,8.2,2.5,9.7,4.7c1.6,2.3,2.5,6.2,2.4,9.6h-19.9c0.1,5.3,3.7,8.7,8.2,8.7c2.1,0,3.8-0.6,5.2-1.9c1.2-1,2.1-2.3,2.7-3.5
			L443,307.7z M438.7,298.7c-0.7-4-3.8-6.7-7.5-6.7c-3.8,0-6.9,2.8-7.5,6.7H438.7z"
      />
      <path
        d="M488.2,302.7h-17.5l-4.9,11.6h-5.1l19-43.2l18.5,43.2h-5.1L488.2,302.7z M486.4,298.4l-6.9-16.5l-7,16.5H486.4z"
      />
      <path
        d="M513.4,294.6c-0.6-1.5-1.9-2.6-3.6-2.6c-1.6,0-3.1,0.9-3.1,2.9c0,1.9,1.2,2.4,4.1,3.6c3.7,1.5,5.4,2.7,6.4,4
			c1.1,1.4,1.4,2.7,1.4,4.1c0,5.4-3.9,8.4-8.8,8.4c-1,0-6.8-0.1-9-6.5l3.8-1.6c0.5,1.4,2,4.2,5.2,4.2c2.9,0,4.2-2.2,4.2-4.1
			c0-2.4-1.7-3.2-4.1-4.3c-3.1-1.4-5.1-2.3-6.2-3.8c-1-1.2-1.2-2.5-1.2-3.8c0-4.5,3.1-7.2,7.5-7.2c1.4,0,5,0.2,7,4.6L513.4,294.6z"
      />
      <path
        d="M535.1,294.6c-0.6-1.5-1.9-2.6-3.6-2.6c-1.6,0-3.1,0.9-3.1,2.9c0,1.9,1.2,2.4,4.1,3.6c3.7,1.5,5.4,2.7,6.4,4
			c1.1,1.4,1.4,2.7,1.4,4.1c0,5.4-3.9,8.4-8.8,8.4c-1,0-6.8-0.1-9-6.5l3.8-1.6c0.5,1.4,2,4.2,5.2,4.2c2.9,0,4.2-2.2,4.2-4.1
			c0-2.4-1.7-3.2-4.1-4.3c-3.1-1.4-5.1-2.3-6.2-3.8c-1-1.2-1.2-2.5-1.2-3.8c0-4.5,3.1-7.2,7.5-7.2c1.4,0,5,0.2,7,4.6L535.1,294.6z"
      />
      <path
        d="M569,307.7c-0.7,1.4-1.9,3-3.1,4.1c-2.3,2.1-5.2,3.1-8.5,3.1c-6.2,0-12.7-3.9-12.7-13.4c0-7.7,4.7-13.6,12.4-13.6
			c5,0,8.2,2.5,9.7,4.7c1.6,2.3,2.5,6.2,2.4,9.6h-19.9c0.1,5.3,3.7,8.7,8.2,8.7c2.1,0,3.8-0.6,5.2-1.9c1.2-1,2.1-2.3,2.7-3.5
			L569,307.7z M564.6,298.7c-0.7-4-3.8-6.7-7.5-6.7s-6.9,2.8-7.5,6.7H564.6z"
      />
      <path
        d="M586.6,294.6c-0.6-1.5-1.9-2.6-3.6-2.6c-1.6,0-3.1,0.9-3.1,2.9c0,1.9,1.2,2.4,4.1,3.6c3.7,1.5,5.4,2.7,6.4,4
			c1.1,1.4,1.4,2.7,1.4,4.1c0,5.4-3.9,8.4-8.8,8.4c-1,0-6.8-0.1-9-6.5l3.8-1.6c0.5,1.4,2,4.2,5.2,4.2c2.9,0,4.2-2.2,4.2-4.1
			c0-2.4-1.7-3.2-4.1-4.3c-3.1-1.4-5.1-2.3-6.2-3.8c-1-1.2-1.2-2.5-1.2-3.8c0-4.5,3.1-7.2,7.5-7.2c1.4,0,5,0.2,7,4.6L586.6,294.6z"
      />
      <path
        d="M608.3,294.6c-0.6-1.5-1.9-2.6-3.6-2.6c-1.6,0-3.1,0.9-3.1,2.9c0,1.9,1.2,2.4,4.1,3.6c3.7,1.5,5.4,2.7,6.4,4
			c1.1,1.4,1.4,2.7,1.4,4.1c0,5.4-3.9,8.4-8.8,8.4c-1,0-6.8-0.1-9-6.5l3.8-1.6c0.5,1.4,2,4.2,5.2,4.2c2.9,0,4.2-2.2,4.2-4.1
			c0-2.4-1.7-3.2-4.1-4.3c-3.1-1.4-5.1-2.3-6.2-3.8c-1-1.2-1.2-2.5-1.2-3.8c0-4.5,3.1-7.2,7.5-7.2c1.4,0,5,0.2,7,4.6L608.3,294.6z"
      />
      <path
        d="M619.5,288.7h4.4v2.7c1.4-2.1,3.6-3.3,6.4-3.3c4.6,0,6.5,3.3,7,4.1c2.5-3.9,6-4.1,7.7-4.1c7.6,0,8.3,6.4,8.3,10v16.2h-4.4
			v-15.6c0-2.9-0.4-6.7-4.6-6.7c-5.1,0-5.7,4.9-5.7,8v14.3h-4.4v-15.2c0-1.8,0.1-7.1-4.6-7.1c-5.1,0-5.6,5.2-5.6,8.3v14h-4.4V288.7z"
      />
      <path
        d="M684.5,307.7c-0.7,1.4-1.9,3-3.1,4.1c-2.3,2.1-5.2,3.1-8.5,3.1c-6.2,0-12.7-3.9-12.7-13.4c0-7.7,4.7-13.6,12.4-13.6
			c5,0,8.2,2.5,9.7,4.7c1.6,2.3,2.5,6.2,2.4,9.6h-19.9c0.1,5.3,3.7,8.7,8.2,8.7c2.1,0,3.8-0.6,5.2-1.9c1.2-1,2.1-2.3,2.7-3.5
			L684.5,307.7z M680.1,298.7c-0.7-4-3.8-6.7-7.5-6.7s-6.9,2.8-7.5,6.7H680.1z"
      />
      <path
        d="M691.5,288.7h4.4v3c0.8-1,3.1-3.6,7.3-3.6c3,0,5.8,1.2,7.3,3.5c1.3,1.9,1.5,4,1.5,6.9v15.9h-4.4v-15.7
			c0-1.5-0.1-3.3-1.2-4.7c-0.9-1.1-2.2-1.9-4.2-1.9c-1.5,0-3.2,0.4-4.5,2c-1.7,2-1.9,5.1-1.9,6.9v13.4h-4.4V288.7z"
      />
      <path d="M725.1,292.7v21.6h-4.4v-21.6h-2.7v-4.1h2.7v-9.3h4.4v9.3h4.4v4.1H725.1z" />
      <path
        d="M746.5,301.6c0-7.8,5.7-13.5,13.6-13.5c7.8,0,13.6,5.7,13.6,13.5c0,7.8-5.8,13.5-13.6,13.5
			C752.3,315,746.5,309.4,746.5,301.6z M751.1,301.6c0,6.3,4.4,9.5,9,9.5c4.6,0,9-3.3,9-9.5c0-6.3-4.3-9.6-9-9.6
			C755.4,292,751.1,295.3,751.1,301.6z"
      />
      <path
        d="M785,292.7v21.6h-4.4v-21.6h-2.5v-4.1h2.5v-11.2c0-2.3,0.2-5.3,2.5-7.2c1.2-1,3.1-1.7,5.1-1.7c1.6,0,2.7,0.4,3.1,0.6v4.3
			c-0.9-0.4-2-0.6-2.9-0.6c-1,0-2.2,0.2-2.8,1.4c-0.6,0.9-0.6,2.3-0.6,3.7v10.7h6.3v4.1H785z"
      />
      <path
        d="M817.4,273.1c2.8,0,6.9,0.2,10,2.3c2.4,1.6,4.5,4.8,4.5,9.1c0,6.3-4.1,10.9-10.4,11.4l13.1,18.4h-5.6l-12.5-18h-1.2v18
			h-4.6v-41.2H817.4z M815.3,292.2h2.9c5.7,0,9.1-2.7,9.1-7.5c0-2.3-0.7-4.6-3.3-6.1c-1.9-1-4-1.2-5.9-1.2h-2.8V292.2z"
      />
      <path
        d="M862.2,307.7c-0.7,1.4-1.9,3-3.1,4.1c-2.3,2.1-5.2,3.1-8.5,3.1c-6.2,0-12.7-3.9-12.7-13.4c0-7.7,4.7-13.6,12.4-13.6
			c5,0,8.2,2.5,9.7,4.7c1.6,2.3,2.5,6.2,2.4,9.6h-19.9c0.1,5.3,3.7,8.7,8.2,8.7c2.1,0,3.8-0.6,5.2-1.9c1.2-1,2.1-2.3,2.7-3.5
			L862.2,307.7z M857.8,298.7c-0.7-4-3.8-6.7-7.5-6.7s-6.9,2.8-7.5,6.7H857.8z"
      />
      <path
        d="M888.8,288.7h4.4v25.6h-4.4v-3.6c-1.5,1.9-4.3,4.3-8.8,4.3c-6.5,0-12.5-4.6-12.5-13.6c0-8.8,5.9-13.4,12.5-13.4
			c4.9,0,7.6,2.7,8.8,4.3V288.7z M872.1,301.4c0,5.5,3.1,9.6,8.4,9.6c5.4,0,8.5-4.5,8.5-9.6c0-6.6-4.6-9.5-8.5-9.5
			C876.1,292,872.1,295.2,872.1,301.4z"
      />
      <path
        d="M921.3,269.3h4.4v45h-4.4v-3.6c-1.5,1.9-4.3,4.3-8.8,4.3c-6.5,0-12.5-4.6-12.5-13.6c0-8.8,5.9-13.4,12.5-13.4
			c4.9,0,7.6,2.7,8.8,4.3V269.3z M904.6,301.4c0,5.5,3.1,9.6,8.4,9.6c5.4,0,8.5-4.5,8.5-9.6c0-6.6-4.6-9.5-8.5-9.5
			C908.6,292,904.6,295.2,904.6,301.4z"
      />
      <path
        d="M935.7,275.8c1.7,0,3.1,1.4,3.1,3.1c0,1.7-1.4,3.1-3.1,3.1c-1.7,0-3.1-1.4-3.1-3.1C932.6,277.2,934,275.8,935.7,275.8z
			 M937.9,288.7v25.6h-4.4v-25.6H937.9z"
      />
      <path
        d="M946.3,288.7h4.4v3c0.8-1,3.1-3.6,7.3-3.6c3,0,5.8,1.2,7.3,3.5c1.3,1.9,1.5,4,1.5,6.9v15.9h-4.4v-15.7
			c0-1.5-0.1-3.3-1.2-4.7c-0.9-1.1-2.2-1.9-4.2-1.9c-1.5,0-3.2,0.4-4.5,2c-1.7,2-1.9,5.1-1.9,6.9v13.4h-4.4V288.7z"
      />
      <path
        d="M995,288.7h4.4v25c0,2.8,0,6.7-2.5,10.1c-1.9,2.6-5.4,4.8-10.3,4.8c-4.4,0-7.4-1.7-9.1-3.5c-1.7-1.7-3.3-4.5-3.3-8.3h4.4
			c0,2.2,0.9,4.1,2,5.3c1.9,2,4.2,2.5,6.2,2.5c3.1,0,5.3-1.1,6.8-3.6c1.3-2.1,1.4-4.4,1.4-6.5v-3.7c-1.5,1.9-4.3,4.3-8.8,4.3
			c-3.8,0-6.8-1.5-8.8-3.5c-1.7-1.7-3.7-4.9-3.7-10.1c0-5,1.9-8.1,3.7-9.9c2.2-2.1,5.3-3.5,8.8-3.5c3.8,0,6.7,1.6,8.8,4.3V288.7z
			 M980.9,294.4c-2.2,2.2-2.6,4.9-2.6,7c0,2.2,0.4,5.2,2.9,7.5c1.4,1.3,3.1,2.1,5.5,2.1s4.2-0.7,5.6-2.2c1.7-1.7,2.9-4.4,2.9-7.4
			c0-3-1.1-5.7-2.7-7.2c-1.4-1.4-3.5-2.3-5.8-2.3C984,292,982.1,293.2,980.9,294.4z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ROARLogo',
};
</script>

<style scoped>
#ROARLogo-Text_Full {
  display: none;
}
.st0 {
  fill: #ffffff;
}
.st1 {
  fill: #231f20;
}

@media screen and (max-width: 1068px) {
  .roar-logo {
    max-width: 120px;
  }
}
@media screen and (max-width: 868px) {
  .roar-logo {
    max-width: 80px;
  }
}
</style>
