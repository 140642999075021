export const ADMINISTRATIONS_QUERY_KEY = 'administrations';
export const ADMINISTRATIONS_LIST_QUERY_KEY = 'administrations-list';
export const ADMINISTRATIONS_STATS_QUERY_KEY = 'administrations-stats';
export const ADMINISTRATION_ASSIGNMENTS_QUERY_KEY = 'administration-assignments';
export const CLASSES_QUERY_KEY = 'classes';
export const DISTRICTS_QUERY_KEY = 'districts';
export const DISTRICTS_LIST_QUERY_KEY = 'districts-list';
export const DISTRICT_SCHOOLS_QUERY_KEY = 'district-schools';
export const DSGF_ORGS_QUERY_KEY = 'dsgf-orgs';
export const FAMILIES_QUERY_KEY = 'families';
export const GROUPS_QUERY_KEY = 'groups';
export const GROUPS_LIST_QUERY_KEY = 'groups-list';
export const LEGAL_DOCS_QUERY_KEY = 'legal-docs';
export const ORG_USERS_QUERY_KEY = 'org-users';
export const ORGS_TABLE_QUERY_KEY = 'orgs-table';
export const SCHOOLS_QUERY_KEY = 'schools';
export const SCHOOL_CLASSES_QUERY_KEY = 'school-classes';
export const SURVEY_RESPONSES_QUERY_KEY = 'survey-responses';
export const TASKS_QUERY_KEY = 'tasks';
export const TASK_VARIANTS_QUERY_KEY = 'task-variants';
export const USER_DATA_QUERY_KEY = 'user';
export const USER_ADMINISTRATION_ASSIGNMENTS_QUERY_KEY = 'user-administration-assignments';
export const USER_ASSIGNMENTS_QUERY_KEY = 'user-assignments';
export const USER_CLAIMS_QUERY_KEY = 'user-claims';
export const USER_STUDENT_DATA_QUERY_KEY = 'user-student';
export const USER_RUN_PAGE_QUERY_KEY = 'user-run-page';
